.subscription {
  position: relative;
  /* width: 350px; */
  /* height: 400px; */
  border-radius: 16px !important;
  padding: 2rem;
  /* border: 1px solid #033454; */
}

.subscription:hover {
  /* box-shadow: 0px 20px 30px rgba(16, 16, 44, 0.589); */
  cursor: pointer;
  border: none;
}

.subscription::before {
  content: "";
  position: absolute;
  background: #2e700e;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: 0;
}

.subscription:hover::before {
  background-color: #2e700e;
  top: 0;
  border-radius: 16px;
  cursor: pointer;
}

.subscription:hover .plan {
  color: #fff;
}

.subscription:hover .planT {
  background-color: #fff;
  color: #033454 !important;
}

.subscription:hover button {
  background: linear-gradient(to right, #bdbdbd, #e0e0e0, #bdbdbd);
  color: #000000;
  font-weight: 600;
}
