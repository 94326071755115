@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.otp-code__input {
  border-radius: 0.5rem;
  font-family: monospace;
  height: 50px;
  border: 1px solid #ccc;
  font-size: 2rem;
  text-align: center;
  transition: all 0.2s ease;
  color: #222;
  width: 3rem !important;
  margin: 0 0.5rem;
}

.active {
  background: linear-gradient(to right, #bdbdbd, #e0e0e0, #bdbdbd);
}
